.singlePages {
  &__header {
    position: relative;
    .singlePages__headerImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 -99px;
      max-height: 230px;
      min-height: 230px;
      filter: brightness(0.3) blur(1px);
    }
    &--closebtn {
      position: absolute;
      padding: 12px 16px;
      background: #e91e63;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      border-radius: 100px;
      right: 10px;
      top: 10px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      z-index: 99;
      cursor: pointer;
    }
  }
  &__headerTitle {
    position: absolute;
    bottom: 35px;
    left: 156px;
    color: white;
    word-break: break-word;
  }
  &__content {
    &--title {
      background: #e91e63;
      color: #fff;
      width: 95%;
      padding: 8px;
      margin: 10px auto;
      border-radius: 3px;
      font-size: 13px;
      overflow: hidden;
    }
  }
}

.singlePages__headerPoster img {
  width: 40%;
  border-radius: 11px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.singlePages__headerPoster {
  position: absolute;
  top: 127px;
  left: 20px;
  border-radius: 11px;
}

.singlePages__content {
  margin-top: 100px;
  padding: 15px;
}

.formGroup {
  width: 95%;
  padding: 8px;
  margin: 10px auto;
  border-radius: 3px;
  font-size: 13px;
  overflow: hidden;
}

.formGroup span:last-child {
  float: right;
}

.formGroup:nth-child(odd) {
  background: #eee;
}
