.card {
  width: 33.3333%;
  padding: 8px;
  &:hover {
    .card__inner {
      cursor: pointer;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transform: translateY(-5px);
    }
  }
  &__inner {
    position: relative;
    border-radius: 11px;
    overflow: hidden;
    max-height: 180px;
    min-height: 180px;
    transition: all 0.35s;

    .card__img {
      width: 100%;
      height: 100%;
      border-radius: 11px;
      object-fit: cover;
      object-position: center;
    }
  }
}
