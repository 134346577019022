.Modal {
  $block: Modal;

  &__modal {
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $dark;
    opacity: 0;
  }

  &__box {
    transition: 0.3s ease;
    position: fixed;
    bottom: -100%;
    width: 480px;
    z-index: 10;
    background-color: $light;
    border-radius: 8px 8px 0 0;
    padding: $gutter-m;
  }

  &__title {
    margin: 0;
  }

  &__description {
    font-size: $font-xs;
    opacity: 0.8;
    line-height: 1.4;
    margin-bottom: $gutter-m;
  }

  &--show {
    z-index: 1;

    .#{$block}__modal {
      opacity: 0.8;
      z-index: 1;
    }

    .#{$block}__box {
      bottom: 0;
    }
  }
}
