* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100vh;
}
#root {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  min-height: 100vh;
  background-color: #fff;
}
