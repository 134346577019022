input[type="text"] {
    width: 100%;
    border: 1px solid #eee;
    padding: 8px;
    border-radius: 11px;
}

input[type="text"]:focus {
    outline: none;
    border: 2px solid #ddd;
}